import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "@app/auth/auth.service";
import { ToasterService } from "@shared/utility/toaster.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService,
    private toaster: ToasterService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const claimType: string = next.data["claimType"];
    const auth = window.sessionStorage.getItem("authentication");
    const isAuthorized = window.sessionStorage.getItem("isAuthorized");
    if (isAuthorized === "false") {
      this.router.navigate(["/forbidden"]);
    }
    if (!auth) {
      window.localStorage.setItem("redirecturl", state.url);
      this.router.navigate([""], { queryParams: { returnUrl: next.url } });
      return false;
    }
    return true;
  }
}
